import React from 'react';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import PageNotFoundOrUserNotAuthorized from '../components/404';

export default function NotFound() {
    return (
        <ResponsiveLayout title="404">
            <PageNotFoundOrUserNotAuthorized />
        </ResponsiveLayout>
    );
}
